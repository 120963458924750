<template>
  <div class="students">
    <div
      class="students_type"
      v-for="(item, index) in studentsType"
      :key="index"
    >
      <h4 v-if="item['students'].length > 0">{{ lang[item.lang] }}</h4>
      <div class="students_list">
        <div
          class="students_list_container"
          v-for="(student, i) in item['students']"
          :key="i"
          @click="personalevaluation(student)"
        >
          <div
            class="students_list_item"
            :style="`background:${
              student.had_feedback ? '#FFF3E8' : '#ffffff'
            }`"
          >
            <img class="headimg" :src="student['headImg']" />
            <span class="students_info"
              >{{
                language == "en"
                  ? student["en_name"] || student["cn_name"]
                  : student["cn_name"] || student["en_name"]
              }}
              <img
                style="padding: 0 5px"
                :class="student.gender == 1 ? 'nan' : 'nv'"
                :src="student.genderImg"
              />
              <!-- <img
                v-if="student.is_supplement"
                :src="common.getRes('buke.png')"
              /> -->
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import request from "@/library/MyRequest";
import { useRouter } from "vue-router";
import Config from "@/library/Config";
export default {
  name: "ClassEvaluation",

  setup() {
    const common = Common;
    const router = useRouter();
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    let state = reactive({
      lang: {
        on_time: "出勤",
        late: "迟到",
        absence: "缺勤",
        leave: "请假",
        unjoin: "尚未加入",
      },
      common: common,
      studentsType: [
        {
          label: "出勤",
          lang: "on_time",
          students: [],
        },
        {
          label: "请假",
          lang: "leave",
          students: [],
        },
        {
          label: "缺勤",
          lang: "absence",
          students: [],
        },
        // {
        //   label: "尚未加入",
        //   lang: "unjoin",
        //   students: [],
        // },
      ],
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    const getStudent = async () => {
      var res = await request.get(
        `/lesson/list-student?lesson_id=${courseData.courseId}`
      );
      console.log("student", res);
      let students = res.data.students;
      students.map((item) => {
        item["headImg"] =
          item.avatar_url == null
            ? common.getRes("default_headImg.png")
            : item.avatar_url;
        // : Config["imgUrl"] + item.avatar_url;
        item["genderImg"] = common.getRes(
          `shuyi_${item.gender == 1 ? "nan" : "nv"}sheng.png`
        );
        switch (item["attendance_state"]) {
          case "ontime":
            state.studentsType[0]["students"].push(item);
            break;
          case "late":
            state.studentsType[0]["students"].push(item);
            break;
          case "leave":
            state.studentsType[1]["students"].push(item);
            break;
          case "absence":
            state.studentsType[2]["students"].push(item);
            break;
          // case "unjoin":
          //   state.studentsType[3]["students"].push(item);
          //   break;
        }
        console.log("state.studentsType", state.studentsType);
      });
    };
    const personalevaluation = (student) => {
      router.push({
        path: "/personalevaluation",
        query: {
          courseData: JSON.stringify({
            courseId: courseData.courseId,
            studentId: student.admission_no,
            had_feedback: student.had_feedback,
          }),
        },
      });
    };
    getStudent();
    return {
      ...toRefs(state),
      common,
      getStudent,
      personalevaluation,
    };
  },
};
</script>
    
<style lang="less" scoped>
.students {
  padding: 20px;
  .students_type {
    margin-bottom: 30px;
    h4 {
      text-align: left;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 48px;
      margin: 0;
      margin-bottom: 30px;
    }
    .students_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .students_list_container {
        width: 50%;
        box-sizing: border-box;
        &:nth-child(even) {
          padding-left: 10px;
        }
        &:nth-child(odd) {
          padding-right: 10px;
        }
      }
      .students_list_item {
        height: 94px;
        padding: 14px;
        background-color: #fff;
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 8px;

        .headimg {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .students_info {
          flex: 1;
          text-align: left;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 38px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>